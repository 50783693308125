import React, {useState, useEffect} from 'react';
import './Lives.css'
import useLives from "../../firebase/useLives";
import Confirmation from "../Confirmation";

const Lives = () => {
    const {lives, consumeLife} = useLives()
    const [hearts, setHearts] = useState([])
    const [showConfirm, setShowConfirm] = useState(false)


    useEffect(() => {
        if(typeof lives === 'number') {
            updateHearts()
        }
    }, [lives])

    const updateHearts = () => {
        const newHearts = []
        for(let i = 0; i < 3; i++) {
            newHearts.push(<div key={i} className={`pixelated-heart ${lives > i ? '' : 'consumed'}`}/>)
        }
        setHearts(newHearts)
    }

    return (
        <>
            {showConfirm && <Confirmation close={() => {setShowConfirm(false)}}
                                          action={consumeLife}
                                          header={'Consume a life?'}
                                          subheader={'Are you sure? Jimbo wont give you more!'}
                                          cancelButtonText={'Cancel'}
                                          actionButtonText={'Consume'}
            />
            }
            <div className='corner-container'>
                <h1>Lives</h1>
                <div className='heart-container' onClick={() => {
                    setShowConfirm(true)
                }}>
                    {hearts}
                </div>
            </div>
        </>
    );
}

export default Lives;