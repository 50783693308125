import React from 'react';
import './Bracket.css'
import {DEFAULT_PFP} from "../../utils/constants";

const Participant = ({participant, winner, score}) => {
    return (
        <div className={`participant ${(winner && winner === participant.displayName) ? 'matchup-winner' : ''}`}>
            {score && <p className='matchup-score'>{score} {score > 1 ? 'pts' : 'pt'}</p> }
            <div className='participant-image-container'>
                {participant.displayName && <img alt='?' src={participant?.image ? participant.image : `../../../${DEFAULT_PFP}`}/>}
                <p>{participant.displayName ? participant.displayName : '- - - - - - - - - -'}</p>
            </div>
        </div>
    )
}

// Calculating TOP value:
// 1) Get the size of the containers on the previous level, and find the midpoint by halving it.
// 2) We cant put the top here or the top of the container would be at them middle, favoring the bottom.
//      So subtract half the container size to get the element centered.
//
// ((Matchup Container Size) * (Num of Containers In Previous Level))                  (Matchup Container Size)
// __________________________________________________________________      - MINUS -  _________________________
//                          2                                                                       2
//
/// ex: Level 2: ((114 + 35) * 4)/2 - ((114 + 35)/2)

// Calculating MARGIN value:
// 1) Take the TOP value from above to get where the element starts, and add that elements height
// 2) Now we have where the bottom of the element will be, subtract this value from the height of the previous
//      level containers so we know how much space is left below our element to fill.
// 3) This value would be the bottom of the previous level, at this elements position.
//      So we add TOP again to position it where it should be padded.
//
//  ((Previous Matchup Container Size) * (Num of Containers in Previous Level)) - Current Container Height
//
//
// ex: Level 2: (114 + 35) * 2) -(75 + 114) + 75
//
const MatchUp = ({matchup, matchupNumber, level, userMapping}) => {
    const firstParticiant = matchup?.player1 ? userMapping[matchup.player1] : {displayName: null}
    const secondParticipant = !matchup?.champ && !matchup?.player2 ? {displayName: null } : userMapping[ matchup?.player2]
    const winnerName = matchup?.winner ? userMapping[matchup.winner].displayName : null
    return (
        <div className='matchup'>
            {matchup?.champ && (
                <h1 className='bracket-champ'>Champion</h1>
            )}
            {matchup?.seed && (
                <h1 className='bracket-label'>{matchup.seed}</h1>
            )}
            <Participant participant={firstParticiant} winner={winnerName} score={matchup?.player1score}/>
            {level !== 0 && (<div className='matchup-merger-line-back'/>)}
            {!matchup?.last && (
                <>
                    {matchupNumber % 2 === 0 && !matchup?.champ && (<div className='matchup-merger-top'/>)}
                    {matchupNumber % 2 === 1 && !matchup?.champ && (<div className='matchup-merger-bottom'/>)}
                </>
                )}
            <Participant participant={secondParticipant} winner={winnerName} score={matchup?.player2score}/>
        </div>
    )
}

const MatchUpWrapper = ({children, level}) => {
    return (
        <div className={`matchup-container-${level}`}>
            {children}
        </div>
    )
}

const Bracket = ({bracket, userMapping}) => {
    const bracketData = bracket[1];

    return (
        <div className='bracket'>
            {
                bracketData.map((matchups, level) => {
                    return (
                        <MatchUpWrapper level={level} key={level}>
                            {Object.values(matchups).map((matchup, index) => {
                                return <MatchUp matchup={matchup} matchupNumber={index} level={level} userMapping={userMapping} key={`${level}-${index}`}/>
                            })}
                        </MatchUpWrapper>)
                })
            }
        </div>
    )
}

export default Bracket;