import {doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";
import getFirebaseConfig from "./firebase-config";
import {USER_DEFAULTS} from "../utils/constants";

const useCoins = () => {
    const {firestore} = getFirebaseConfig()
    const [isConsumingCoins, setIsConsumingCoins] = useState(false)
    const [isBetting, setIsBetting] = useState(false)

    const fetchUserCoins = async (user) => {
        const userDocRef = doc(firestore, 'users', user.uid);

        try {
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                return userData.coins || 0
            }
        } catch (error) {
            console.error('Error fetching user coins:', error);
            return 0
        }
    };

    const consumeCoins = async ({user, amountToConsume, coins}) => {
        if (amountToConsume > 0 && amountToConsume <= coins && !isConsumingCoins) {
            setIsConsumingCoins(true);

            const userDocRef = doc(firestore, 'users', user.uid);

            // Decrease the number of coins by the specified amount
            const updatedCoins = coins - amountToConsume;

            try {
                // Update the Firestore document to reduce the number of coins
                await updateDoc(userDocRef, {
                    coins: updatedCoins,
                });
                return updatedCoins
            } catch (error) {
                console.error('Error consuming coins:', error);
            } finally {
                setIsConsumingCoins(false);
            }
        }
    };

    const giveCoins = async({user, amount, coins}) => {
        const userDocRef = doc(firestore, 'users', user.uid);

        try {
            // Update the Firestore document to reduce the number of coins
            await updateDoc(userDocRef, {
                coins: coins + amount,
            });
        } catch (error) {
           console.log('Unable to give coins', error)
        }
    }

    const doBet = async ({user, bet}) => {
        if(!isBetting) {
            setIsBetting(true)
            const wagerDocRef = doc(firestore, 'wagers', user.uid);
            try {
                const docSnapshot = await getDoc(wagerDocRef);
                if (docSnapshot.exists()) {
                    await updateDoc(wagerDocRef, bet);
                } else {
                    await setDoc(wagerDocRef, bet);
                }
            } catch (error) {
                console.error('Error consuming coins:', error);
            } finally {
                setIsBetting(false)
            }
        }
    }

    const getBets = async (user) => {
        const wagerDocRef = doc(firestore, 'wagers', user.uid);
        try {
            const docSnapshot = await getDoc(wagerDocRef);

            if (docSnapshot.exists()) {
                return docSnapshot.data();
            }
            return {}
        } catch (error) {
            console.error('Error fetching user coins:', error);
            return 0
        }
    }

    const markBetSettled = async({user, bet}) => {
        const wagerDocRef = doc(firestore, 'wagers', user.uid);
        try {
            await updateDoc(wagerDocRef, bet);
            return true
        } catch (error) {
            console.error('Unable to settle bet, cannot assign coins', error);
            return false
        }
    }

    const getWagerStates = async () => {
        const wagerDocRef = doc(firestore, 'wagers', 'wager_state');
        const docSnapshot = await getDoc(wagerDocRef);
        return docSnapshot.data();
    }

    return { fetchUserCoins, doBet, getBets, getWagerStates, consumeCoins, giveCoins, markBetSettled }
}

export default useCoins;

