import {collection, getDocs} from "firebase/firestore";
import getFirebaseConfig from "./firebase-config";

const useWinners = () => {
    const fetchWinners = async () => {
        const {firestore} = getFirebaseConfig()
        const winnerCollection = collection(firestore, 'winners');
        let winners = {}
        try {
            const querySnapshot = await getDocs(winnerCollection);
            querySnapshot.forEach((doc) => {
                winners = doc.data()
            });
            return winners
        } catch (error) {
            console.error('Error fetching winner:', error);
            return winners
        }
    }

    return {fetchWinners}
}

export default useWinners;