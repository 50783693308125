import React, { useState} from 'react';
import './Account.css';
import Lives from "../components/account/Lives";
import Coins from "../components/account/Coins";
import useUser from "../firebase/useUser";

function AccountPage() {
    const {save, isSaving} = useUser()
    const [displayName, setDisplayName] = useState('');
    const [image, setImage] = useState(null);

    const handleDisplayNameChange = (e) => {
        setDisplayName(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleSave = () => {
        save({image, displayName})
    }

    return (
        <div>
            <div className='account-container'>
                <div className='corner-container'>
                    <h1>Account Settings</h1>
                    <div className='account-labels'>
                        <label>Display Name:</label>
                        <input type="text" value={displayName} onChange={handleDisplayNameChange} />
                    </div>
                    <div className='account-labels'>
                        <label>Avatar:</label>
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                    </div>
                    <button className='button save-button' onClick={handleSave} disabled={isSaving}>Save</button>
                </div>
                <Lives />
            </div>
            <Coins />
        </div>
    );
}

export default AccountPage;