import React, {useEffect, useState} from "react";
import './ScoreBar.css'
import {DEFAULT_PFP} from "../../utils/constants";
import Loading from "../Loading";

const ScoreBar = ({data, userMapping, mostPointsOverride= null}) => {
    const [scoresWithStyles, setScoresWithStyles] = useState([])

    const buildStyles = () => {
        let mostPoints
        if (mostPointsOverride) {
            mostPoints = mostPointsOverride
        } else {
            mostPoints = data.players.reduce((accumulator, player) => {
                return accumulator + player.score
            }, 0)
        }
        const updatedScores = []
        for(let player of data.players) {
            if(userMapping[player.uid]) {
                const width = Math.max((player.score / mostPoints) * 100, 10);
                let name
                let image
                try {
                    name = userMapping[player.uid].displayName
                } catch {
                    name = ''
                }
                try {
                    image = userMapping[player.uid].image
                } catch {
                    image = ''
                }
                const score = {
                    score: player.score,
                    barStyle: {width: `${width}%`},
                    uid: player.uid,
                    displayName: name,
                    image: image
                };
                updatedScores.push(score)
            }
        }
        updatedScores.sort((a, b) => {
            if(a.score > b.score) {
                return -1;
            }
            if(a.score < b.score) {
                return 1;
            }
            return 0;
        })
        setScoresWithStyles(updatedScores)
    }

    useEffect(() => {
      buildStyles(data)
    }, [])

    return (
        <div>
            {scoresWithStyles.length > 0 && (
                <div className="high-scores-container">
                    {scoresWithStyles.map((score) => (
                        <div className='bar-container' key={score.uid}>
                            <div className="high-score-bar" style={score?.barStyle}>
                                <div className='square-container'>
                                    {score?.image && <img className='img' src={score?.image ? score.image : `../../../${DEFAULT_PFP}`}/> }
                                </div>
                                <span className='bar-name'>{score.displayName}</span>
                            </div>
                            <span className='bar-score'>{score?.score || 0}</span>
                        </div>
                    ))}
                </div>
            )}
            {scoresWithStyles.length === 0 && (
                <Loading text={"Loading"}/>
            )}
        </div>
    )
}

export default ScoreBar;