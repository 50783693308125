import {doc, getDoc, updateDoc} from "firebase/firestore";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";

const useLives = () => {
    const {firestore, user} = useContext(AppContext)
    const [isConsumingLife, setIsConsumingLife] = useState(false);
    const [lives, setLives] = useState()

    const fetchUserLives = async () => {
        const userDocRef = doc(firestore, 'users', user.uid);

        try {
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setLives(userData.lives || 0);
            }
        } catch (error) {
            console.error('Error fetching user lives:', error);
        }
    };

    // Function to consume one life
    const consumeLife = async () => {
        if (lives > 0 && !isConsumingLife) {
            setIsConsumingLife(true);

            const userDocRef = doc(firestore, 'users', user.uid);
            const updatedLives = lives - 1;

            try {
                await updateDoc(userDocRef, {
                    lives: updatedLives,
                });

                setLives(updatedLives);
            } catch (error) {
                console.error('Error consuming life:', error);
            } finally {
                setIsConsumingLife(false);
            }
        }
    };

    useEffect(() => {
        fetchUserLives();
    }, [])

    return {lives, consumeLife}
}

export default useLives;