import React, { useEffect, useState } from 'react';
import './Loading.css'

const Loading = ({text}) => {
    const [fadeInClass, setFadeInClass] = useState('')
    useEffect(() => {
        setTimeout(() => {
            setFadeInClass('on')
        }, 100)
    }, [])

    return (
        <h2>{`>  ${text} `}
            <span className={`period one ${fadeInClass}`}>.</span>
            <span className={`period two ${fadeInClass}`}>.</span>
            <span className={`period flash ${fadeInClass}`}>.</span>
        </h2>
    )
}

export default Loading;