import React from 'react';
import './Sphere.css'

const Sphere = () => {
    return (
        <div className="world">
            <div className='text-in-sphere'>Gamer Olympics</div>
            <div className="cube">
                <div className="circle zero"/>
                <div className="circle one"/>
                <div className="circle two"/>
                <div className="circle three"/>
                <div className="circle four"/>
                <div className="circle five"/>
                <div className="circle six"/>
            </div>
        </div>
    )
}

export default Sphere;