import {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";
import {collection, getDocs} from "firebase/firestore";
import getFirebaseConfig from "./firebase-config";

const useBrackets = () => {
    const {firestore} = getFirebaseConfig();
    const [bracketData, setBracketData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchBrackets = async () => {
        const bracketsCollection = collection(firestore, 'brackets');
        const brackets = {};

        try {
            const querySnapshot = await getDocs(bracketsCollection);
            querySnapshot.forEach((doc) => {
                brackets[`${doc.id}`] = doc.data();
            });

            setBracketData(brackets);
            setIsLoading(false);
            return brackets
        } catch (error) {
            console.error('Error fetching FFA battles:', error);
            setIsLoading(false);
            return []
        }
    };

    // useEffect(() => {
    //     fetchBrackets();
    // }, [])

    return { fetchBrackets, isLoading}
}

export default useBrackets;