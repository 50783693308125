import React, {useState} from 'react';
import {AppContext} from "../../store/AppContext";
import {useContext} from "react";
import './Header.css'
import { useNavigate } from 'react-router-dom';
import useUser from "../../firebase/useUser";

const HeaderMenu = ({ show, goToAccountPage, goToHomePage, goToBrackets, goToWagers, goToHighScores }) => {
    const {logout}  = useUser()

    return (
        <>
            {show && (
                <div className='menu'>
                    <div onClick={goToHomePage}>Home</div>
                    <div onClick={goToAccountPage}>Account</div>
                    <div onClick={goToHighScores}>Highscores</div>
                    <div onClick={goToBrackets}>Brackets</div>
                    <div onClick={goToWagers}>Wagers</div>
                    <div onClick={logout}>Logout</div>
                </div>
            )}
        </>
    )
}

const Header = () => {
    const { user, userImage } = useContext(AppContext)
    const [showHeaderMenu, setShowHeaderMenu] = useState(false)

    const navigate = useNavigate();

    const goToAccountPage = () => {
        navigate('/account');
    };

    const goToHomePage = () => {
        navigate('/')
    }

    const goToBrackets = () => {
        navigate('/brackets')
    }

    const goToWagers = () => {
        navigate('/wagers')
    }

    const goToHighScores = () => {
        navigate('/highscores')
    }

    return (
        <div className='header'>
            <div className='title' onClick={goToHomePage}>Gamer Olympics</div>
            <div className='user' onClick={() => { setShowHeaderMenu((prev) => {return !prev})}}>
                <div className='square-container'><img className='pfp' src={userImage} /></div>
                <div className='name'>{user.displayName ? user.displayName : user.uid}</div>
                <HeaderMenu
                    show={showHeaderMenu}
                    goToAccountPage={goToAccountPage}
                    goToHomePage={goToHomePage}
                    goToBrackets={goToBrackets}
                    goToWagers={goToWagers}
                    goToHighScores={goToHighScores}
                />
            </div>
        </div>
    )
}

export default Header;