import React, { useEffect, useState } from 'react'
import './Stars.css'

const Stars = () => {
    const [stars, setStars] = useState([]);

    useEffect(() => {
        const createStar = () => {
            return {
                left: `${Math.random() * 96}vw`,
                top: `${Math.random() * 96}vh`,
                animationDelay: `${Math.random() * 5}s`,
                size: `${Math.random() * 4}px`,
            };
        };

        const generateStars = () => {
            const starArray = Array(200).fill(null).map(() => createStar());
            setStars(starArray);
        };

        generateStars();
    }, []);

    return (
        <div className="twinkling-stars">
            {stars.map((star, index) => {
                return <div
                    key={index}
                    className="star"
                    style={{
                        left: star.left,
                        top: star.top,
                        animationDelay: star.animationDelay,
                        width: star.size,
                        height: star.size,
                    }}
                />
            })}
        </div>
    );
}

export default Stars;