import {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";
import {GAMES} from "../utils/constants";
import './WagerPage.css'
import {Coin} from "../components/account/Coins";
import Confirmation from "../components/Confirmation";
import useCoins from "../firebase/useCoins";

const WagerPage = () => {
    const {brackets, ffas, coins, userMapping, user, setCoins, wagerStates} = useContext(AppContext)
    const {consumeCoins, doBet, getBets} = useCoins()
    const [categories, setCategories] = useState([])
    const [unsavedBets, setUnsavedBets] = useState({})
    const [displayCoins, setDisplayCoins] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [isPlacingBet, setIsPlacingBet] = useState(false)
    const [betToSave, setBetToSave] = useState({})
    const [knownBets, setKnownBets] = useState()

    useEffect(() => {
        if(brackets && ffas && categories.length === 0) {
           refresh()
        }
    }, [brackets, ffas, categories])


    const refresh = async () => {
        const cats = [...Object.keys(brackets), ...Object.keys(ffas)]
        const defaultBets = {}
        for(let c of cats) {
            defaultBets[c] = {}
            for(let uid of Object.keys(userMapping)) {
                defaultBets[c][uid] = {bet: 0, odds: 0}
            }
        }
        const userBets = await getBets(user)
        setKnownBets(userBets)
        setUnsavedBets(defaultBets)
        setCategories(cats)
        setBetToSave({})
    }

    const adjustBetAmount = (category, uid, increase, odds) => {
        if (
            (unsavedBets[category][uid].bet === 0 && !increase) ||
            (displayCoins === 0 && increase) ||
            isPlacingBet
        ) {
            return
        }
        let betAmount = increase ? 1 : -1
        let displayAmount = increase ? -1 : 1
        const newBets = JSON.parse(JSON.stringify(unsavedBets))
        newBets[category][uid].bet += betAmount
        newBets[category][uid].odds = odds
        const newBetToSave = JSON.parse(JSON.stringify(betToSave))
        if(newBets[category][uid].bet > 0) {
            if (!newBetToSave.hasOwnProperty(category)) {
                newBetToSave[category] = {
                    guess: uid,
                    wager: newBets[category][uid].bet
                }
            } else {
                newBetToSave[category].guess = uid
                newBetToSave[category].wager = newBets[category][uid].bet
            }
        } else {
            delete newBetToSave[category]
        }
        setBetToSave(newBetToSave)
        setUnsavedBets(newBets);
        setDisplayCoins(displayCoins + displayAmount)
    }

    useEffect(() => {
        if(coins !== undefined) {
            setDisplayCoins(coins)
        }
    }, [coins])

    const placeBet = async () => {
        setIsPlacingBet(true)
        const newCoins = await consumeCoins({user, amountToConsume: Math.abs(displayCoins - coins), coins })
        await doBet({user, bet: betToSave})
        setDisplayCoins(newCoins)
        setCoins(newCoins)
        refresh()
        setIsPlacingBet(false)
    }

    const calculateUpside = () => {
        let totalUpside = 0
        for(let category of Object.values(unsavedBets)) {
            for(let bet of Object.values(category)) {
                if(bet.bet) {
                    totalUpside += (bet.bet * bet.odds)
                }
            }
        }
        return totalUpside
    }

    const isBettingDisabled = (category, uid) => {
        let disabled = false
        if(
            (betToSave.hasOwnProperty(category) &&
                betToSave[category].guess !== uid) ||
            knownBets.hasOwnProperty(category) ||
            wagerStates[category] !== 'open'
            ){
            disabled = true
        }
        return disabled
    }

    if(Object.keys(unsavedBets).length === 0 || displayCoins === undefined) {
        return <></>
    }

    return (
        <div>
            {showConfirmation && (
                <Confirmation close={() => {setShowConfirmation(false)}}
                              action={placeBet}
                              header={'Really place bet?'}
                              subheader={'Are you sure you dont want some schnapps first?'}
                              extra={`Total Upside: ${calculateUpside()} coins.`}
                              cancelButtonText={'Cancel'}
                              actionButtonText={'DIAL IN BABY!'}
                />
            )}
            <h1 className='wager-header'>Feeling lucky?</h1>
            <p className='wager-header'>Wager on the <u>WINNER</u> of the category</p>
            <div className='wager-coins-counter'>Coins:
                <Coin />
                <span className='wager-x'>x</span>
                {displayCoins}
            </div>
            <div className='wager-button'>
                <button className='button' disabled={coins === displayCoins || isPlacingBet} onClick={() => {setShowConfirmation(true)}}>Lock In</button>
            </div>
            <div className='wagers'>
                {categories.map((category) => {
                    if(category.indexOf('loser') > -1) {
                        return <></>
                    }
                    return (
                        <div className='wager' key={category}>
                            <h1 className={wagerStates[category] !== 'open' ? 'wager-category-closed' : ''}><u>{GAMES[category]}</u></h1>
                            {Object.keys(userMapping).map((uid) => {
                                const isUserThatWasBetOn = knownBets.hasOwnProperty(category) && knownBets[category].guess === uid
                                return (
                                    <div className='wager-user' key={uid}>
                                        <div className='square-container'>
                                            <img src={userMapping[uid].image} />
                                        </div>
                                        <div className='wager-name-container'>
                                            <p>{userMapping[uid].displayName}</p>
                                            <p >Odds: {userMapping[uid].odds[category]}x</p>
                                        </div>
                                        <div className='wager-field'>
                                            <Coin />
                                            <span className='wager-x'>x</span>
                                            <div className='wager-amount'>
                                                {isUserThatWasBetOn ? knownBets[category].wager : unsavedBets[category][uid].bet}
                                                <i className={`arrow up ${isBettingDisabled(category, uid) ? 'bet-disabled' : ''}`} onClick={() => {
                                                    adjustBetAmount(category, uid, true, userMapping[uid].odds[category])
                                                }}/>
                                                <i className={`arrow down ${isBettingDisabled(category, uid) ? 'bet-disabled' : ''}`} onClick={() => {
                                                    adjustBetAmount(category, uid, false, 0)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WagerPage;