import React from 'react';
import './App.css';

import AppProvider from "./store/AppContext";
import Routing from "./Routing";
import Monitor from "./components/monitor/Monitor";
import Loading from "./components/Loading";

function App() {
  return (
      <AppProvider>
          <Monitor>
              {/*<Loading text={'Coming Soon'} />*/}
              <Routing />
          </Monitor>
      </AppProvider>
  );
}

export default App;
