import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA9JKPrLvLVnY91MAeVrw0xJRVbvH8ZPJM',
  authDomain: 'gamer-olympics.firebaseapp.com',
  projectId: 'gamer-olympics',
  storageBucket: 'gamer-olympics.appspot.com',
  messagingSenderId: '33535142310',
  appId: '1:33535142310:web:f81e2b152659ea5a6b8767',
};

let app;
let auth;
let firestore;

const getFirebaseConfig = () => {
  if (!app && !auth) {
    app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    firestore = getFirestore(app);
  }
  return { app, auth, firestore };
};

export default getFirebaseConfig;
