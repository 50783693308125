import React, {useRef, useEffect} from 'react'
import {Puddle} from './puddle'
import './PuddleEffect.css'
const PuddleEffect = ({children}) => {
    const puddleRef = useRef(null)
    const listener = useRef(null)
    useEffect(() => {
        const makePuddle = () => {
            let puddle = new Puddle(puddleRef.current);
            puddle.setupGrid();
            puddle.setNodeStyle('ascii');
            puddle.setNodeSize(20);
            puddle.setMaxRippleStrength(80);
            puddle.setDampeningRatio(.5);
        }
        if(puddleRef.current && !listener.current) {
            makePuddle()
            window.addEventListener("resize", () => {makePuddle()});
            listener.current = makePuddle

            return (() => {
                window.removeEventListener("resize", listener.current)
            })
        }
    }, [puddleRef.current])

    return (<div className='puddle-effect' ref={puddleRef}>{children}</div>)
}

export default PuddleEffect;