import React, {useContext} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginOrSignUp from "./components/auth/LoginOrSignUp";
import { AppContext } from "./store/AppContext";
import HomePage from "./pages/HomePage";
import AccountPage from "./pages/AccountPage";
import Header from "./components/header/Header";
import './App.css'
import BracketPage from "./pages/BracketPage";
import WagerPage from "./pages/WagerPage";
import HighScoresPage from "./pages/HighScoresPage";

const Routing = () => {
    const { user } = useContext(AppContext)

    if(!user) {
        return (
            <div className="app">
                <LoginOrSignUp />
            </div>
        )
    }
    return (
        <BrowserRouter>
            <Header/>
            <div className='app'>
                <Routes>
                    <Route exact path="/account" element={<AccountPage />} />
                    <Route exact path="/brackets" element={<BracketPage />}/>
                    <Route exact path="/wagers" element={<WagerPage />}/>
                    <Route exact path="/highscores" element={<HighScoresPage />}/>
                    <Route path="/" element={<HomePage />}/>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default Routing;