import {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";
import {collection, getDocs} from "firebase/firestore";
import getFirebaseConfig from "./firebase-config";

const useFFA = () => {
    const {firestore} = getFirebaseConfig();
    const [ffaData, setFFAData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchFFAs = async () => {
        const ffaCollection = collection(firestore, 'ffa');
        const ffaBattles = {};

        try {
            const querySnapshot = await getDocs(ffaCollection);
            querySnapshot.forEach((doc) => {
                ffaBattles[`${doc.id}`] = doc.data();
            });

            setFFAData(ffaBattles);
            setIsLoading(false);
            return ffaBattles
        } catch (error) {
            console.error('Error fetching FFA battles:', error);
            setIsLoading(false);
            return {}
        }
    };

    // useEffect(() => {
    //     fetchFFAs()
    // }, [])


   return {fetchFFAs, isLoading}
}

export default useFFA;