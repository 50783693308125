import React, {useContext, useState} from 'react';
import Bracket from "../components/bracket/Bracket";
import './BracketPage.css'
import {GAMES} from "../utils/constants";
import ScoreBar from "../components/bracket/ScoreBar";
import {AppContext} from "../store/AppContext";


const BracketPage = () => {
    const {ffas, brackets, userMapping} = useContext(AppContext)

    const [selectedBracket, setSelectedBracket] = useState('')

    if(!ffas || !brackets || !userMapping) {
        return <></>
    }
    const bracketNames = [...Object.keys(brackets), ...Object.keys(ffas)]
    bracketNames.sort((a,b) => {
       return (GAMES[a].localeCompare(GAMES[b]))
    })
    return (
        <div className='brackets-page'>
            <div className='brackets-filter'>
                {bracketNames.map((bracketName) => {
                    return (
                        <div className={`bracket-name ${selectedBracket === bracketName ? 'selected' : ''}`} onClick={() => {
                            setSelectedBracket(bracketName)
                        }
                        }>
                            {GAMES[bracketName]}
                        </div>)
                })}
            </div>
            {Object.keys(brackets).map((bracketName, i) => {
                if(selectedBracket === bracketName) {
                    return (
                        <>
                            <h1 key={`header-${i}`} className='bracket-header'>{GAMES[bracketName]}</h1>
                            <Bracket bracket={brackets[bracketName]} userMapping={userMapping} key={i}/>
                        </>
                    )
                }
            })}
            {Object.keys(ffas).map((ffaName, i) => {
                if(selectedBracket === ffaName) {
                    return (
                        <>
                            <h1 key={`header-${i}`} className='bracket-header'>{GAMES[ffaName]}</h1>
                            <ScoreBar data={ffas[ffaName]} userMapping={userMapping} key={i} mostPointsOverride={11}/>
                        </>
                    )
                }
            })}

        </div>
    )
}

export default BracketPage