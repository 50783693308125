import React, {useContext} from 'react';
import './HomePage.css';
import Stars from "../components/effects/Stars";
import PuddleEffect from "../components/puddle/PuddleEffect";
import Podium from "../components/effects/Podium";
import {AppContext} from "../store/AppContext";
import Fireworks from "../components/fireworks/Fireworks"


function HomePage() {
    const { winners } = useContext(AppContext)
    return (
        <>
            <Stars />
            {winners?.first && <Fireworks />}
            <div className='home-page-welcome'>
                {!winners?.first &&
                <>
                    <h2>Welcome to the</h2>
                    <h1>Gamer Olympics</h1>
                </>
                }
                {winners?.first &&
                <>
                    <h2>2024 Gamer Olympics</h2>
                    <h1>CHAMPIONS</h1>
                </>
                }
                <div className='podiums'>
                    <Podium place='2nd' position='second' name={winners?.second}/>
                    <Podium place='1st' name={winners?.first}/>
                    <Podium place='3rd' position='third' name={winners?.third}/>
                </div>

            </div>
            {!winners?.first && <PuddleEffect />}
        </>
    )
}
export default HomePage;






