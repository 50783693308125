import {
    createUserWithEmailAndPassword,
    updateProfile,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import uploadFile from "../utils/imageUpload";
import {collection, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";
import {useContext, useState} from "react";
import {AppContext} from "../store/AppContext";
import {GAMES, USER_DEFAULTS} from "../utils/constants";
import getFirebaseConfig from "./firebase-config";

const useUser = () => {
    const {firestore} = getFirebaseConfig();
    const { auth, user, setUserImage} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const [accountError, setAccountError] = useState('')


    const handleErrors =  (error) => {
        setAccountError(error.message.replace('Firebase:', ''))
    }

    const save = async ({image, displayName}) => {
        setIsSaving(true)
        if(displayName) {
            // Update the display name in Firebase Authentication
            try {
                await updateProfile(user, {
                    displayName: displayName,
                });
            } catch (error) {
                console.error('Error updating display name:', error);
            }
        }
        // Upload the image to Firebase Cloud Storage
        if (image || displayName) {
            let url = null
            const data = {}
            if(image) {
                url = await uploadFile(image)
            }
            if (url) {
                data.image = url
            }
            if (displayName) {
                data.displayName = displayName
            }
            if(Object.keys(data).length > 0) {
                const userImagesRef = doc(firestore, 'users', user.uid);
                const userImagesDoc = await getDoc(userImagesRef);
                if (!userImagesDoc.exists()) {
                    try {
                        await setDoc(userImagesRef, data);
                        setUserImage(url)
                    } catch (error) {
                        console.error('Error creating the document:', error);
                    }
                } else {
                    await updateDoc(userImagesRef, data);
                    setUserImage(url)
                }
            }
        }
    };

    const setUserDefaults = async (userRef) => {
        const usersCollection = collection(firestore, 'users');
        const userDocRef = doc(usersCollection, userRef.uid);
        const odds = {}
        for(let game of Object.keys(GAMES)) {
            odds[game] = 1;
        }
        await setDoc(userDocRef, {
            uid: userRef.uid,
            ...USER_DEFAULTS,
            odds
        });
    }

    const signUp = ({email, password}) => {
        setAccountError('')
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // New user created and signed in successfully
                const { user } = userCredential;
                console.log(`Signed up as: ${user.email}`);
                sendEmailVerification(user)
                  .then(() => {
                      // Email verification sent
                      console.log('Email verification sent');
                  })
                  .catch((error) => {
                      console.error('Error sending email verification:', error);
                  });
               setUserDefaults(user)
            })
            .catch((error) => {
                handleErrors(error)
            });
    }

    const signIn = ({email, password}) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // User signed in successfully
                const {user} = userCredential;
            })
            .catch((error) => {
                handleErrors(error)
            });
    }

    const logout = () => {
        return signOut(auth)
    }

    const sendPasswordReset = async (email) => {
        return await sendPasswordResetEmail(auth, email)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    const fetchAllUsers = async () => {
        const userCollection = collection(firestore, 'users');
        const users = {}

        try {
            const querySnapshot = await getDocs(userCollection);
            querySnapshot.forEach((doc) => {
                const userData = doc.data()
                users[`${doc.id}`] = {
                    displayName: userData.displayName || userData?.uid,
                    image: userData?.image,
                    odds: userData?.odds || {}
                };
            });

           return users
        } catch (error) {
            console.error('Error fetching users:', error);
           return {}
        }
    }

    return {save, setUserDefaults, signUp, signIn, logout, sendPasswordReset, fetchAllUsers, isSaving, accountError}
}

export default useUser;