const apiKey = '0439e9e48db71bf82d1c57990be2c621';

const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    return await fetch('https://api.imgbb.com/1/upload?key=' + apiKey, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            console.log('Uploaded image');
            return data.data.url;
        })
        .catch((error) => {
            console.error('Error uploading image:', error);
            return null
        });
};

export default uploadFile