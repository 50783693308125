export const DEFAULT_PFP = 'default_pfp.jpg'

export const USER_DEFAULTS = {
    displayName: '',
    lives: 3,
    coins: 10,
}

export const GAMES = {
    golf: 'Golf With Friends',
    league: 'League 1v1s',
    fortnite: 'Fortnite BR',
    halo: 'Halo SWAT',
    starcraft: 'Starcraft 1v1s',
    battleship: 'Battleship',
    handsim: 'Hand Simulator',
    pogostuck: 'Pogostuck 10min',
    geoguessr: 'GeoGuessr',
    loser_league: 'League 1v1 - Losers',
    loser_geoguessr: ' GeoGuessr - Losers',
    loser_starcraft: 'Starcraft 1v1s - Losers',
    loser_battleship: 'Battleship - Losers',
    loser_halo: 'Halo SWAT - Losers'

}