import './Podium.css'
import {useContext} from "react";
import {AppContext} from "../../store/AppContext";

const Podium = ({place = '1st', name, position = 'first'}) => {
    const { userMapping } = useContext(AppContext)
    const mappedName = name ? userMapping[name].displayName : 'TBD'
    return (
        <div className={`podium ${position}`}>
            <h2>{mappedName}</h2>
            <div className="side front">{place}</div>
            <div className="side left">{place}</div>
            <div className="side right">{place}</div>
            <div className="side back">{place}</div>
        </div>
    )
}

export default Podium;