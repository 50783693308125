import React, { useContext} from 'react';
import './Coins.css'
import {AppContext} from "../../store/AppContext";

export const Coin = () => {
    return <div className='coin smaller-coin'/>
}

const Coins = () => {
    const {coins} = useContext(AppContext)

    return (
        <div className='coins-container'>
            <div className='corner-container'>
                <h1>Coins:</h1>
                <div className='coins'>
                    <div className='coin'/>
                    <p>x</p>
                    <p id='coin-amount'>{coins}</p>
                </div>
            </div>
        </div>
    );
}

export default Coins;