import React from 'react';
import './Confirmation.css'

const Confirmation = ({close, action, header, subheader, cancelButtonText, actionButtonText, extra = ''}) => {
    return (
        <>
            <div className='confirmation-background'/>
            <div className='confirmation'>
                <h1>{header}</h1>
                <p>{subheader}</p>
                {extra && <p>{extra}</p>}
                <div className='confirmation-buttons'>
                    <button className='button' onClick={close}>{cancelButtonText}</button>
                    <button className='button' onClick={() => {
                        action()
                        close()
                    }}>{actionButtonText}</button>
                </div>
            </div>
        </>
    )
}

export default Confirmation;