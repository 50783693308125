import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../store/AppContext";
import {DEFAULT_PFP} from "../utils/constants";
import ScoreBar from "../components/bracket/ScoreBar";

const HighScoresPage = () => {
    const { ffas, brackets, userMapping } = useContext(AppContext)
    const [highScores, setHighScores] = useState({});
    const [mostPoints, setMostPoints] = useState()
    const addPlayerData = (map, player) => {
        if(map.hasOwnProperty(player.uid)) {
            map[player.uid].score += player.score
        } else if(userMapping[player.uid]){
            const displayName = userMapping[player.uid]?.displayName || 'Unknown'
            const image = userMapping[player.uid]?.image || DEFAULT_PFP
            map[player.uid] =   {
                displayName,
                uid: player.uid,
                image,
                score: player.score
            }
        }
    }
    useEffect(() => {
        const highScoresData = {};
        // Function to fetch high scores
        const fetchHighScores = async () => {
            for(let category of Object.values(brackets)) {
                for(let matchups of category['1']) {
                    for(let matchup of Object.values(matchups)) {
                        if(matchup.hasOwnProperty('player1score')) {
                            addPlayerData(highScoresData, {uid: matchup.player1, score: matchup.player1score})
                        }
                        if(matchup.hasOwnProperty('player2score')) {
                            addPlayerData(highScoresData, {uid: matchup.player2, score: matchup.player2score})
                        }
                    }
                }
            }
            for(let category of Object.values(ffas)) {
                for(let player of Object.values(category.players)) {
                    addPlayerData(highScoresData, player)
                }
            }
            let highestPoints = 0;
            for(let user of Object.values(highScoresData)) {
                if(user.score > highestPoints) {
                    highestPoints = user.score
                }
            }
            setHighScores(highScoresData)
            setMostPoints(highestPoints)
        };
        if(ffas && brackets) {
            fetchHighScores();
        }
    }, [ffas, brackets]);

    return (
        <div>
            <h1>High Scores</h1>
            {Object.keys(highScores).length > 0 && (
                <ScoreBar data={{players: Object.values(highScores)}} userMapping={userMapping} mostPointsOverride={mostPoints} />
            )}
        </div>
    );
}

export default HighScoresPage