import React, { createContext, useState, useEffect,  } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import getFirebaseConfig from "../firebase/firebase-config";
import {DEFAULT_PFP} from "../utils/constants";
import useBrackets from "../firebase/useBrackets";
import useFFA from "../firebase/useFFA";
import useUser from "../firebase/useUser";
import useCoins from "../firebase/useCoins";
import useWinners from "../firebase/useWinners";

export const AppContext = createContext(
    {
        firestore: null,
        auth: null,
        user: null
    }
);

const AppProvider = ({ children }) => {
    const { auth, firestore } = getFirebaseConfig();
    const { fetchBrackets } = useBrackets({firestore})
    const { fetchFFAs } = useFFA({firestore})
    const [userImage, setUserImage] = useState()
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState(false)
    const [brackets, setBrackets] = useState()
    const [ffas, setFfas] = useState()
    const [coins, setCoins] = useState()
    const [userMapping ,setUserMapping] = useState()
    const [wagerStates, setWagerStates] = useState({})
    const { fetchAllUsers } = useUser({firestore, auth, user})
    const { fetchUserCoins, getWagerStates, giveCoins, getBets, markBetSettled } = useCoins()
    const [winners, setWinners] = useState({})
    const { fetchWinners } = useWinners()

    useEffect(() => {
        // Listen for changes in authentication state
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            setUser(authUser);
            setLoading(false);
        });

        // Clean up the listener when the component unmounts
        return unsubscribe;
    }, []);

    useEffect(() => {
        const getUserImage = () => {
            const userImagesRef = doc(firestore, 'users', user.uid);
            try {
                getDoc(userImagesRef).then((userImagesDoc) => {
                    if (userImagesDoc.exists()) {
                        // Get the image data from the document
                        const imageData = userImagesDoc.data().image;
                        setUserImage(imageData ? imageData : DEFAULT_PFP)
                    } else {
                        // Handle the case where the document does not exist
                       setUserImage(DEFAULT_PFP)
                    }
                }).catch((err) => {
                    console.log(err)
                });
            } catch (error) {
                console.error('Error fetching user image:', error);
                throw error;
            }
        }
        if(user && !userImage) {
            getUserImage()
        }
    }, [user, userImage])

    useEffect(() => {
        const getData = async () => {
            const brackets = await fetchBrackets()
            const ffas = await fetchFFAs()
            const mapping = await fetchAllUsers()
            const c = await fetchUserCoins(user)
            const states = await getWagerStates()
            const w = await fetchWinners()
            setBrackets(brackets)
            setFfas(ffas)
            setUserMapping(mapping)
            setCoins(c)
            setWagerStates(states)
            setWinners(w)
            settleBets({user, states, coins: c, mapping})
        }
        if(user && firestore && !fetchedData) {
            setFetchedData(true)
            getData()
        }
    }, [user, firestore, fetchedData])


    const settleBets = async ({mapping, user, states, coins}) => {
        const wagers = await getBets(user)
        for(let category of Object.keys(wagers)) {
            const bet = wagers[category]
            if(states[category] !== 'open') {
                if(!bet?.settled) {
                    if(bet.guess === bet?.winner) {
                        const winnerOdds = mapping[bet.winner].odds[category]
                        const winnings = bet.wager * winnerOdds
                        const settledBet = {[`${category}`] : {...bet, settled: true}}
                        const settled = await markBetSettled({user, bet: settledBet})
                        if(settled) {
                            giveCoins({user, amount: winnings, coins})
                        }
                    }
                    else if (bet?.winner && !bet?.settled) {
                        const settledBet = {[`${category}`] : {...bet, settled: true}}
                        await markBetSettled({user, bet: settledBet})
                    }
               }
           }
        }
    }
    const userState = {
        auth,
        firestore,
        user,
        userImage,
        setUserImage,
        loading,
        brackets,
        ffas,
        coins,
        setCoins,
        userMapping,
        wagerStates,
        winners
    }

    return (
        <AppContext.Provider value={userState}>
            {children}
        </AppContext.Provider>
    );
};


export default AppProvider;