import React, {useRef, useState} from 'react';
import './Auth.css'
import Sphere from "../effects/Sphere";
import Stars from "../effects/Stars";
import useUser from "../../firebase/useUser";


function LoginOrSignUp() {
    const {signUp, signIn, sendPasswordReset, accountError} = useUser()
    const [message, setMessage] = useState()
    const emailRef = useRef()
    const passwordRef = useRef()

    const handleSignIn = () => {
        setMessage('')
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        signIn({email, password})
    }

  // const handleSignUp = () => {
  //     setMessage('')
  //     const email = emailRef.current.value
  //     const password = passwordRef.current.value;
  //     signUp({email, password})
  // }

  const doPasswordReset = async () => {
        const email = emailRef.current.value
        if(email) {
            await sendPasswordReset(email)
        }
        emailRef.current.value = ''
        setMessage('If the email exists a reset link will be sent.')
  }

  return (
    <div>
        <Stars />
        <Sphere />
        <form className='form' id="auth-form">
            {accountError && (
                  <div className='message error'>
                      <h3>{accountError}</h3>
                  </div>
            )}
            {message && (
                <div className='message notify'>
                    <h3>{message}</h3>
                </div>
            )}
            <input type="email" id="email" placeholder="Email" ref={emailRef} required />
            <input type="password" id="password" placeholder="Password" ref={passwordRef} required />
            <div className='login-buttons'>
                <button className='button' type="button" onClick={handleSignIn}>Login</button>
            </div>
            <a className='password-reset' onClick={doPasswordReset}>Reset Password</a>
      </form>
    </div>
  );
}

export default LoginOrSignUp;
