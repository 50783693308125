import React, { useState, useEffect } from 'react';
import './Monitor.css'

const Monitor = ({children}) => {
    const [scanlineClass, setScanlineClass] = useState('')
    const startScanline = () => {
        setScanlineClass('start')
        setTimeout(() => {
            setScanlineClass('')
        }, 1450)
    }
    function startRandomScanlines() {
        const randomInterval = Math.random() * (10000 - 5000) + 5000

        startScanline();

        setTimeout(startRandomScanlines, randomInterval);
    }

    useEffect(() => {
        startRandomScanlines()
    },[])

    return (
        <div className="noisy">
            <div className="emblem">
                <span className="icon-information"></span>
            </div>
            <div className="frame">
                <div className="piece output">
                    {children}
                </div>
                <div className="piece scanlines noclick">
                    <div className={`scanline ${scanlineClass}`}/>
                </div>
                <div className="piece glow noclick"></div>
            </div>
        </div>
    )
}

export default Monitor;