import { Fireworks } from 'fireworks-js'
import {useEffect, useRef} from "react";
import './Fireworks.css'

const options = {
    autoresize: true,
    opacity: 0.2,
    acceleration: 1.05,
    friction: 0.97,
    gravity: 1.5,
    particles: 200,
    traceLength: 3,
    traceSpeed: 1,
    explosion: 10,
    intensity: 15,
    flickering: 80,
    lineStyle: 'square',
    hue: {
        min: 130,
        max: 170
    },
    delay: {
        min: 30,
        max: 60
    },
    rocketsPoint: {
        min: 50,
        max: 50
    },
    lineWidth: {
        explosion: {
            min: 1,
            max: 4
        },
        trace: {
            min: .1,
            max: 2.75
        }
    },
    brightness: {
        min: 50,
        max: 80
    },
    decay: {
        min: 0.015,
        max: 0.03
    },
    mouse: {
        click: false,
        move: false,
        max: 1
    },
    sound: {
        enabled: true,
        files: [
            '/explosion0.mp3',
            '/explosion1.mp3',
            '/explosion2.mp3'
        ],
        volume: {
            min: 2,
            max: 4
        }
    }
}

const fireworks = () => {
    const fireworks = useRef()
    const interval = useRef()

    useEffect(() => {
        interval.current = setInterval(() => {
            const container = document.getElementById('fireworks')
            if(container) {
                fireworks.current = new Fireworks(container, options)
                fireworks.current.start()
                clearInterval(interval.current)
            }
        }, 200)

        return () => {
            console.log('disconnecting!')
            if(interval.current) {
                clearInterval(interval.current)
            }
            if(fireworks.current) {
                fireworks.current.stop()
            }
        }
    }, [])

    return <div id='fireworks' />

}

export default fireworks